/* Ensure center alignment for the entire app */
.app {
    text-align: center;
  }
  
  /* Container for the main image */
  .image-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }
  
  /* Background image styles */
  .background-image {
    width: 100%;
    display: block;
    vertical-align: middle;
  }
  
  /* Container for headings */
  .headings-container {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    color: white;
    width: 80%;
    margin-left: 40px;
  }
  
  /* Sub heading styles */
  .sub-heading {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  /* Main heading styles */
  .heading {
    font-size: 2rem;
  }
  
  /* Container for heading 1 */
  .heading1-container {
    text-align: left;
    margin-top: 3rem;
    padding: 0 20px;
  }
  
  /* Heading 1 styles */
  .heading1 {
    font-size: 2rem;
    color: #333;
    margin-left: 90px;
    margin-right: 80px;
    text-align: left;
  }
  
  /* Paragraph styles */
  .Paragraph {
    text-align: justify;
    margin-top: 1rem;
    line-height: 1.6;
    margin-left: 90px;
    margin-right: 80px;
    margin-bottom: 0; /* Remove bottom margin */
  }
  
  /* Button styles */
  .button-container button {
    margin: 0 10px;
    padding: 12px 24px; /* Padding around button text */
    background-color: #f1f3f5; /* Blue background color */
    color: #0e0d0d; /* White text color */
    border: none; /* Remove default button border */
    cursor: pointer; /* Pointer cursor on hover */
    outline: none; /* Remove default focus outline */
    font-size: 16px;
    border-radius: 5px; /* Rounded corners */
    width: 200px;
  }
  
  /* Active button style */
  .button-container button.active {
    background-color: #070707; /* Darker blue for active state */
    color: #f1f3f5;
  }
  
  /* Image and text container */
  .image-and-text {
    display: flex;
    align-items: center; /* Align items vertically */
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
  }
  
  /* Image styles */
  .side-image {
    max-width: 200px; /* Adjust the maximum width of the image */
    margin-left: 100px;
    height: 150px; /* Maintain aspect ratio */
  }
  
  /* Text content styles */
  .text-content {
    flex: 1; /* Take up remaining space */
  }
  
  /* Heading and paragraph styles */
  .heading2 {
    font-size: 1.5rem;
    color: #333;
    margin-left: 90px;
    margin-right: 80px;
    text-align: left;
  }
  
  .Paragraph1 {
    text-align: justify;
    margin-top: 1rem;
    line-height: 1.6;
    margin-left: 90px;
    margin-right: 80px;
    margin-bottom: 0; /* Remove bottom margin */
  }
  
  /* Specific heading styles */
  .heading-p {
    text-align: justify;
    margin-top: 1rem;
    line-height: 1.6;
    margin-left: 90px;
    margin-right: 190px;
    color: rgb(5, 117, 161);
    margin-bottom: 0; /* Remove bottom margin */
  }
  
  /* Button containers */
  .buttonnn1, .buttonnn2, .buttonnn3, .buttonnn4 {
    display: flex;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .heading1, .Paragraph, .heading2, .Paragraph1, .heading-p {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .sub-heading {
      font-size: 2rem;
    }
    .heading {
      font-size: 1.5rem;
    }
    .side-image {
      max-width: 150px;
      margin-left: 10px;
    }
  }
  
  @media (max-width: 375px) {
    .image-and-text {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align items to the start */
    }
    .sub-heading {
      font-size: 1.5rem;
    }
    .heading {
      font-size: 1rem;
    }
    .heading1, .Paragraph, .heading2, .Paragraph1, .heading-p {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 1rem;
    }
    .side-image {
      max-width: 100%;
      margin-left: 0;
    }
    .text-content {
      width: 100%; /* Ensure text content takes full width */
      margin-top: 10px; /* Add some space between image and text */
    }
  }
  
  @media (max-width: 250px) {
    .image-and-text {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align items to the start */
    }
    .sub-heading {
      font-size: 1rem;
    }
    .heading {
      font-size: 0.8rem;
    }
    .heading1, .Paragraph, .heading2, .Paragraph1, .heading-p {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 0.8rem;
    }
    .side-image {
      max-width: 100%;
      margin-left: 0;
    }
    .text-content {
      width: 100%; /* Ensure text content takes full width */
      margin-top: 5px; /* Add some space between image and text */
    }
  }
  
  





  #buttonnn1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
  }
  
  #side-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 10px;
  }
  
  #content {
    text-align: left;
  }
  
  #heading2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  #heading-p1, #heading-p2 {
    font-weight: bold;
    margin-top: 20px;
  }
  
  #Paragraph1, #Paragraph2 {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (min-width: 768px) {
    #buttonnn1 {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
    }
  
    #side-image {
      margin-right: 20px;
      max-width: 200px;
    }
  
    #content {
      max-width: 600px;
    }
  
    #heading2 {
      font-size: 28px;
    }
  
    #Paragraph1, #Paragraph2 {
      font-size: 18px;
    }
  }
  



  #buttonnn2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
  }
  
  #side-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 10px;
  }
  
  #content {
    text-align: left;
  }
  
  #heading2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  #heading-p1, #heading-p2 {
    font-weight: bold;
    margin-top: 20px;
  }
  
  #Paragraph1, #Paragraph2 {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (min-width: 768px) {
    #buttonnn2 {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
    }
  
    #side-image {
      margin-right: 20px;
      max-width: 200px;
    }
  
    #content {
      max-width: 600px;
    }
  
    #heading2 {
      font-size: 28px;
    }
  
    #Paragraph1, #Paragraph2 {
      font-size: 18px;
    }
  }
  