/* Ensure center alignment for the entire app */
.app {
    text-align: center;
  }
  
  /* Container for the main image */
  .image-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }
  
  /* Background image styles */
  .background-image {
    width: 100%;
    display: block;
    vertical-align: middle;
  }
  
  /* Container for headings */
  .headings-container {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    color: white;
    width: 80%;
    margin-left: 40px;
  }
  
  /* Sub heading styles */
  .sub-heading {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  /* Main heading styles */
  .heading {
    font-size: 2rem;
  }
  
  /* Container for heading 1 */
  .heading1-container {
    text-align: left;
    margin-top: 3rem;
    padding: 0 20px;
  }
  
  /* Heading 1 styles */
  .heading1 {
    font-size: 2rem;
    color: #333;
    margin-left: 90px;
    margin-right: 80px;
    text-align: left;
  }
  
  /* Paragraph styles */
  .Paragraph {
    text-align: justify;
    margin-top: 1rem;
    line-height: 1.6;
    margin-left: 90px;
    margin-right: 80px;
  }
  
  /* Image side container */
  .image-side {
    flex: 0 0 40%;
  }
  
  /* Side image styles */
  .side-image {
    width: 80%;
    height: 40%;
    display: block;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  /* Flex container for additional content */
  .pp {
    display: flex;
  }
  
  /* Additional content styles */
  .additional-content {
    text-align: left;
    margin-top: 20px;
  }
  
  /* Blue heading styles */
  .blue-heading {
    color: rgb(38, 164, 248);
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  /* Blue paragraph styles */
  .blue-paragraph {
    color: #333;
    font-size: 1rem;
    text-align: justify;
    line-height: 1.6;
  }
  
  /* Plus icon styles */
  .plus-icon {
    font-size: 1.5rem;
    margin-right: 10px;
    transition: transform 0.3s ease;
    color: rgb(19, 170, 230);
  }
  
  /* Rotate class for plus icon */
  .rotate {
    transform: rotate(45deg);
  }
  
  /* Accordion item container */
  .accordion-item {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  
  /* Accordion content styles */
  .accordion-content {
    padding: 10px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
    color: #ddd;
    background-color: #333;
  }
  
  /* Paragraph styles within accordion content */
  .accordion-content p {
    margin: 0;
    text-align: justify;
    padding: 10px;
    color: #ddd;
    background-color: #333;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    /* Adjust layout for combined content */
    .pp {
      flex-direction: column;
      align-items: center;
    }
  
    /* Adjust text container for smaller screens */
    .text-container {
      margin-bottom: 20px;
    }
  
    /* Adjust image side for smaller screens */
    .image-side {
      margin-top: 20px;
      margin-left: 40px;
    }
  }
  
  /* Media query specifically for 320px width */
  @media (max-width: 320px) {
    /* Ensure no alignment changes */
    /* Adjust specific styles if necessary */
  
  }
  